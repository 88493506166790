aside a em {
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  /* font-weight: 300; */
  line-height: 20px;
  /* color: black; */
}

aside a svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

aside a {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  gap: 6px;
  align-items: center;
  border-radius: 0 10px 10px 0;
  padding: 17px 17px;
  text-decoration: none !important;
}

.list-close svg {
  /* color: black; */
  font-size: 39px;
  margin-top: 22px;
}

aside a.active {
  background: #ff8088;
  /* padding: 13.4px 17px; */
  color: black;
}

aside a em {
  color: black;
  font-weight: 800;
  /* color: var(--main-color); */
}

aside a.active em {
  color: black;
}

/* background: #D8FFE0; */
aside {
  width: 280px;
  height: 100%;
  overflow-y: auto;
  background-color: #ffc7c8;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 30px 0px 19px 0;
  position: sticky;
  padding-top: 0;
  top: 0;
  transition: 0.4s all ease-in-out;
}

aside .logo-ic img {
  width: 129.573px;
  height: 129.573px;
  flex-shrink: 0;
  margin: auto;
  border-radius: 165px;
}

aside .logo-ic {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

aside .links {
  /* padding: 20px; */
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  margin-top: 24px;
  flex-direction: column;
  gap: 3px;
}

.side {
  position: sticky !important;
  top: 0;
  height: 100vh;
}

aside::-webkit-scrollbar-track {
  border-radius: 2px;
}

aside::-webkit-scrollbar {
  width: 0;
  background-color: var(--third-color);
  transition: 0.5s ease-in-out;
}

aside:hover::-webkit-scrollbar {
  width: 6px;
}

aside::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bfbfbf;
}

.list-close {
  display: none;
  font-size: 30px;
  color: black !important;
  margin-bottom: 10px;
}

.list-close svg {
  cursor: pointer;
}

@media (max-width: 992px) {
  .list-close {
    display: block;
  }

  aside.hidden {
    display: none;
  }
}
