.OrderModel .items h3 {
  text-align: center;
  width: fit-content;
  padding: 10px 15px;
  border-bottom: 0.1px solid rgb(217, 217, 217);
  margin: 10px auto;
}

.OrderModel .items h2 {
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 10px 15px;
}

.OrderModel {
  padding: 12px;
  width: min(400px, 100%);
  width: 80%;
  position: fixed;
  overflow-y: auto;
  height: 100%;
  z-index: 9999999999999999999 !important;
  top: 0;
  left: 43%;
  translate: -50%;
  background: white;
  box-shadow: 1px 0 24px -2px rgb(217, 217, 217);
  border-radius: 4px;
}

.table_btns button {
  font-size: 14px;
  white-space: nowrap;
}

.table_btns {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  font-size: 14px;
}

#order-status-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  justify-content: center;
  width: 264px;
  margin: 7px 0;
}

#order-status-form label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

#order-status-form input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

#order-status-form button[type="submit"] {
  padding: 10px 20px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

#order-status-form input {
  display: none !important;
}

#order-status-form input:checked + span {
  background: rgb(85, 16, 16);
  color: white;
}

.edit_model {
  padding: 10px;
  position: fixed;
  left: 5%;
  top: 30%;
  background: white;
  box-shadow: 1px 0px 24px -3px rgb(204, 204, 204);
  border-radius: 5px;
}
@media print {
  body {
    margin: 0;
    overflow: hidden;
  }

  .printable {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .OrderModel .items h3 {
    text-align: center;
    width: fit-content;
    padding: 10px 15px;
    border-bottom: 0.1px solid rgb(217, 217, 217);
    margin: 10px auto;
  }

  .OrderModel .items h2 {
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 10px 15px;
  }

  .OrderModel {
    padding: 12px;
    width: min(400px, 100%);
    width: 80%;
    position: fixed;
    overflow-y: auto;
    height: 100%;
    z-index: 999999999999999999999999999999999999999999999999999999999999999999 !important;
    top: 0;
    left: 43%;
    translate: -50%;
    background: white;
    box-shadow: 1px 0 24px -2px rgb(217, 217, 217);
    border-radius: 4px;
  }

  .table_btns button {
    font-size: 14px;
    white-space: nowrap;
  }

  .table_btns {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    font-size: 14px;
  }

  #order-status-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: center;
    width: 264px;
    margin: 7px 0;
  }

  #order-status-form label {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  #order-status-form input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  #order-status-form button[type="submit"] {
    padding: 10px 20px;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }

  #order-status-form input {
    display: none !important;
  }

  #order-status-form input:checked + span {
    background: rgb(85, 16, 16);
    color: white;
  }

  .edit_model {
    padding: 10px;
    position: fixed;
    left: 5%;
    top: 30%;
    background: white;
    box-shadow: 1px 0px 24px -3px rgb(204, 204, 204);
    border-radius: 5px;
  }
}

.filteration {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
}

.message_details p{
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.message_details p pre{
  margin: 0;
  font-weight: 700;
  white-space: pre-wrap;
}

.message_details p:not(:last-child) pre{
  /* color:  red; */
  padding: 10px;
  border: .1px solid var(--main-color-1);
  border-radius: 6px;
  display: flex;
  align-items: center;
}