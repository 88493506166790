.add_form {
  width: 100%;
}

.add_form>* {
  display: block;
}

.add_form label {
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  margin: 4px;
  display: block;
  text-align: start;
}

.add_form input,
.add_form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-bottom: 10px;
}

.add_form>div {
  margin-bottom: 10px;
  text-align: center;
}

.add_form>div button {
  background-color: #1f9f63;
  color: white;
  padding: 10px;
  width: 100px;
  font-size: 18px;
  border-radius: 4px;
}

.del_btns {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.search_component {
  margin: 20px 0px;
}

.search_component input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.add_form select {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
}
