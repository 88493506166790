:root {
  --main-color: #ff8088;
  --light-color: #dedede;
  --other-main-color: #ffc7c8;
  --second-color: #3fd1b1;
  --third-color: #e90704;
}

* {
  position: relative;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: myFirstFont;
  src: url(./assets/fonts/Tajawal-Light.ttf);
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  direction: rtl;
  font-family: myFirstFont;
}

.add_btn {
  padding: 10px;
  width: 100px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  background-color: #0b5ed7;
  border-radius: 4px;
}

.table {
  overflow-x: auto !important;
}

.pageName h3 span {
  font-size: 28px;
}

::-webkit-scrollbar {
  /* width: 10px; */
}

.table {
  overflow-x: auto;
}

/* Change the track to a blue color and give a round border */
::-webkit-scrollbar-track {
  /* background-color: blue; */
  border-radius: 10px;
}

/* Making the thumb (which shows how much you've scrolled) a gray color
and making it round */
::-webkit-scrollbar-thumb {
  /* background: red; */
  border-radius: 10px;
}

/* A dark gray color when hovered overn */
::-webkit-scrollbar-thumb:hover {
  /* background: red; */
}

@media (max-width: 768px) {
  .pageName h3 span {
    font-size: 11px;
  }
}

.form-control {
  width: 90% !important;
  margin: auto;
}

.form-control input,
.form-control textarea {
  display: block;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  outline: none;
}

.form-control textarea {
  height: 100px;
}

.form-control label {
  font-size: 22px;
  margin-bottom: 5px;
}

.site_images {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 92%;
  padding: 10px;
  margin: auto;
  /* margin-top: 20px; */
  background-color: #fff;
  flex-wrap: wrap;
}

.site_images>div {
  width: 47%;
}

.site_images>div * {
  display: block;
  margin-bottom: 10px;
}

.site_images>div input {
  display: block;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  outline: none;
}

.site_images>div label {
  font-size: 22px;
}

.site_images img {
  width: 100%;
}

@media (max-width:767px) {
  .site_images>div {
    width: 100%;
  }
}