.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f5f7;
}

.login .login_form {
  background-color: white;
  width: 700px;
  max-width: 100%;
  height: 500px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.login .login_form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
}

.login .login_form input {
  width: 100%;
  display: block;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.login_form img {
  width: 200px;
  max-width: 100%;
}

.login .login_form button {
  background-color: var(--third-color);
  color: white;
  padding: 10px;
  border-radius: 4px;
  width: 100px;
  max-width: 100%;
  font-size: 22px;
}